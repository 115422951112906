<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import FormItem from '@/components/Input/FormItem.vue'
import TextEditor from '@/components/Input/TextEditor/index.vue'
import { useSaveSectionOnScroll } from './useSaveSectionOnScroll'
import Checkbox from '@/components/Input/Checkbox.vue'
import debounce from 'lodash/debounce'

export default defineComponent({
  components: {
    FormItem,
    TextEditor,
    ValidationObserver,
    Checkbox,
},
  props: {
    value: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    tiktokCategories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    rendering: {
      type: Boolean,
      required: true,
    },
    detail: {
      type: Object,
      required: true,
    },
    permission: {
      type: Array,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { el, dirty, validationObserver } = useSaveSectionOnScroll(emit)

    /**
     * @type {import('@vue/composition-api').Ref<import('@/types/product').ProductFormModel['detail']>}
     */
    const model = ref(props.value.detail)
    const edit = ref(false)
    const expanded = ref(false)
    watch(() => props.value, () => {
      console.log('props.value.detail: ', props.value.detail)
      model.value = props.value.detail
    }, { deep: true })

    const categoryOptions = computed(() => props.categories || [])

    const categoryOptionsTiktok = computed(() => props.tiktokCategories || [])

    const search = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    watch(() => props.loading, () => {
      if (!props.loading) {
        edit.value = false
        emit('changeEditable', {detail: false})
        const firstErrorElement = document.querySelector('#detail-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }, { deep: true })

    const handleEdit = () => {
      edit.value = !edit.value
      emit('changeEditable', {detail: edit.value})
      if (edit.value === false) {
        const firstErrorElement = document.querySelector('#detail-product')
        model.value.title = props.detail.title
        model.value.long_description = props.detail.long_description
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    const truncated = (e) => {
      if (e) return e.length > 300 ? e.substring(0, 300) + '...' : e;
    }

    const changeExpanded = () => {
      expanded.value = !expanded.value
      if (expanded.value === false) {
        const firstErrorElement = document.querySelector('#detail-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    const submitEdit = () => {
      const payload = {
        section: 1,
        detail: {
            title: model.value.title,
            description: model.value.long_description,
        },
      }
      emit('saveEdit', payload)
    }

    const inputAttribute = debounce((attr, index) => {
      emit('onChangeChannelAttribute', {
        attribute_id: attr.id,
        attribute_value_list: [{
          value_id: undefined,
          original_value_name: attr.value,
        }],
        is_mandatory: attr.input_type.is_mandatory,
      })
    }, 500)

    const selectAttribute = debounce((value, attr, index) => {
      let attribute_value_list = []
      if(attr.input_type.is_multiple_selected) {
        attribute_value_list = value.map(val => {
          return {
            value_id: val.key,
            original_value_name: val.label.trim(),
          }
        })
      } else {
        attribute_value_list = value?.key
          ? [{
              value_id: value.key,
              original_value_name: value.label.trim(),
            }]
          : []
      }
      emit(
        'onChangeChannelAttribute', 
        {
          attribute_id: attr.id,
          attribute_value_list,
          is_mandatory: attr.input_type.is_mandatory,
        },
      )
    }, 500)

    const findAttributeValue = (attributeId) => {
      return model.value.attribute_list.find((obj) => obj.id === attributeId)?.values?.map(obj => obj.name).join(', ')
    }
    return {
      model,
      categoryOptions,
      categoryOptionsTiktok,
      validationObserver,
      el,
      dirty,
      search,
      edit,
      truncated,
      expanded,
      changeExpanded,
      handleEdit,
      submitEdit,
      inputAttribute,
      selectAttribute,
      findAttributeValue,
    }
  },
})
</script>

<template>
  <ValidationObserver ref="validationObserver" slim>
    <a-card ref="el">
      <a-skeleton v-if="rendering" active />
      <div v-else>
        <div class="mb-5 d-flex">
          <div>
            <h4 id="detail-product">
              {{ $t('product.product_details') }}
            </h4>
          </div>
          <div v-if="$route.query.edit && !edit && permission.includes('WRITE')" class="ml-auto">
            <a-button
              type="primary"
              size="large"
              :ghost="edit ? false : true"
              @click="handleEdit"
            >
              Edit
            </a-button>
          </div>
        </div>


        <div v-if="$route.query.edit && !edit">
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.product_name') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.title }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('tiktok.categoryMaster') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.category_name }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              Kategori Produk Tiktok
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.category_details?.map(v => {return v?.data?.local_display_name || v?.local_name})?.join(" - ") }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.brand') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.brand_name || '-' }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.item_condition') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.condition === 'NEW' ? 'Baru' : 'Bekas' }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.importLabel') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.imported ? 'Impor' : 'Lokal' }}
            </div>
          </a-col>
          <template v-if="model.attribute_list">
            <a-col :span="24">
              <div class="title-detail d-flex justify-content-start mb-2">
                {{ $t('product.specification') }}
              </div>
            </a-col>
            <a-row v-if="loading" :gutter="16">
              <a-col :span="24">
                <a-skeleton :loading="loading" active />
              </a-col>
            </a-row>
            <a-row v-else :gutter="16">
              <a-col class="mb-2" :span="24">
                <ul class="text-left mb-2">
                  <li
                    v-for="(attribute) in attributes"
                    :key="attribute.id"
                    :span="12"
                  >
                    <span class="mr-2" style="color: var(--gray-dark);">{{ `${attribute.name}:` }}</span>
                    <span>{{ findAttributeValue(attribute.id) || '-' }}</span>
                  </li>
                </ul>
              </a-col>
            </a-row>
          </template>
          <a-col class="mt-2" :sm="{ span: 24 }">
            <div>
              <label class="d-block title-detail mb-3">{{ $t('product.description') }}</label>
              <div v-html="expanded ? model.long_description : truncated(model.long_description)" />
              <a v-if="model.long_description?.length > 300" style="color: blue" @click="changeExpanded"> {{ expanded ? 'Show Less' : 'Show More' }} </a>
            </div>
          </a-col>
        </div>

        <section v-else>
          <div class="font-weight-semibold mb-4">
            {{ $t('product.product_information') }}
          </div>
          <div>
            <label class="mb-1">
              {{ $t('product.product_name') }} <sup class="text-danger">*</sup>
            </label>
            <FormItem
              :name="$t('product.product_name')"
              vid="title"
              rules="required|min:25"
              class="pcaFormItemLabel"
            >
              <a-input
                v-model="model.title"
                class="h-48px"
                focus
              />
            </FormItem>
            <!--<label class="mb-1">
              {{ $t('tiktok.categoryMaster') }} <sup class="text-danger">*</sup>
            </label>
            <FormItem
              :name="$t('tiktok.categoryMaster')"
              vid="category"
              rules="required"
            >
              <a-cascader
                v-model="model.category_id"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :field-names="{ label: 'name', value: 'id', children: 'child' }"
                :options="categoryOptions"
                placeholder=""
              />
            </FormItem>-->
            <label class="mb-1">
              Kategori Produk Tiktok <sup class="text-danger">*</sup>
            </label>
            <FormItem
              name="Kategori Produk Tiktok"
              vid="category"
              rules="required"
            >
              <a-cascader
                v-model="model.category_tiktok_id"
                class="text-left w-100 h-48px"
                popup-class-name="kategori-options"
                :field-names="{ label: 'name', value: 'category_id', children: 'children' }"
                :options="categoryOptionsTiktok"
                :show-search="{ search }"
                placeholder=""
                :disabled="$route.query.edit ? true : false"
                @change="$emit('fetchVariant', $event)"
              />
            </FormItem>
            <label class="mb-1">
              {{ $t('product.brand') }}
            </label>
            <FormItem
              :name="$t('product.brand')"
              vid="brand"
            >
              <a-input
                v-model="model.brand_id"
                class="h-48px"
              />
            </FormItem>
            <div v-if="model?.category_tiktok_id?.length > 0" class="border p-3 rounded">
              <label class="d-block font-weight-semibold mb-3">{{ $t('product.specification') }}</label>
              <a-row v-if="loading" :gutter="16">
                <a-col :span="12">
                  <a-skeleton :loading="loading" active />
                </a-col>
                <a-col :span="12">
                  <a-skeleton :loading="loading" active />
                </a-col>
              </a-row>
              <a-row v-else :gutter="16">
                <a-col
                  v-for="(attribute, index) in attributes"
                  :key="attribute.id"
                  :span="12"
                >
                  <label class="mb-1">
                    {{ attribute.name }}
                    <sup v-if="attribute.input_type.is_mandatory" class="text-danger">*</sup>
                  </label>
                  <FormItem
                    :name="attribute.name"
                    :rules="attribute.input_type.is_mandatory ? 'required' : ''"
                    class="pcaFormItemLabel"
                  >
                    <a-input
                      v-if="!attribute.input_type.is_multiple_selected && !attribute?.values?.length"
                      v-model="attribute.value"
                      size="large"
                      @change="inputAttribute(attribute, index)"
                    />
                    <a-select
                      v-else
                      v-model="attribute.value"
                      :mode="attribute.input_type.is_multiple_selected && !attribute?.values?.length
                        ? 'tags'
                        : attribute.input_type.is_multiple_selected && attribute?.values?.length
                          ? 'multiple'
                          : 'default'"
                      size="large"
                      class="select-antd-default"
                      label-in-value
                      allow-clear
                      @change="selectAttribute($event, attribute, index)"
                    >
                      <a-select-option
                        v-for="attr in attribute.values"
                        :key="attr.id"
                        :value="attr.id"
                      >
                        {{ attr.name }}
                      </a-select-option>
                    </a-select>
                  </FormItem>
                </a-col>
              </a-row>
            </div>
            <a-row class="mt-5">
              <a-col :sm="{ span: 8 }">
                <div class="mb-3">
                  <label class="d-block font-weight-semibold mb-3">{{ $t('product.item_condition') }}</label>
                  <FormItem>
                    <Checkbox
                      v-model="model.condition"
                      check-value="USED"
                      uncheck-value="NEW"
                      :label="$t('product.used_condition')"
                      class="ml-2"
                      :disabled="$route.query.edit ? true : false"
                    />
                  </FormItem>
                </div>
              </a-col>
              <a-col :sm="{ span: 8 }">
                <div class="mb-3">
                  <label class="d-block font-weight-semibold mb-3">{{ $t('product.importLabel') }}</label>
                  <FormItem>
                    <Checkbox v-model="model.imported" :label="$t('product.import')" class="ml-2" :disabled="$route.query.edit ? true : false" />
                  </FormItem>
                </div>
              </a-col>
            </a-row>

            <div v-if="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'">
              <label class="mb-1">
                {{ $t('product.short_description') }}
              </label>
              <FormItem
                :name="$t('product.short_description')"
                vid="brand"
              >
                <a-input
                  v-model="model.short_description"
                  class="h-48px"
                  placeholder="Maks. 70 Karakter"
                />
              </FormItem>
            </div>

            <div class="mt-4">
              <label class="d-block font-weight-semibold mb-3">
                {{ $t('product.description') }}
                <sup class="text-danger">*</sup>
              </label>
              <FormItem :name="$t('product.description')" vid="description" rules="required|min:25">
                <TextEditor
                  v-model="model.long_description"
                  :placeholder="$t('product.write_something')"
                />
              </FormItem>
            </div>
          </div>
        </section>
        <div v-if="$route.query.edit && edit" class="mt-3 py-3 text-right footer">
          <a-button
            size="large"
            type="primary"
            ghost
            class="px-5 mr-3 ml-auto"
            :loading="loading"
            @click="handleEdit"
          >
            {{ $t('utils.cancel') }}
          </a-button>

          <a-button
            size="large"
            type="primary"
            class="px-5"
            :loading="loading"
            @click="submitEdit"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </div>
    </a-card>
  </ValidationObserver>
</template>

<style lang="scss">
.kategori-options ul.ant-cascader-menu {
  height: auto;
  max-height: 180px;
}
</style>
